import React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { isEmpty } from "lodash"

import Seo from "../components/seo"
import Layout from "../components/layout"

const MemberPageTemplate = ({ data: { member } }) => {
  const image = getImage(member.avatar)

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="py-3 whitespace-pre-line">
          {children}
        </p>
      ),
    },
  }
  
  return (
    <Layout>
      <Seo
        title={member.firstname + ` ` + member.lastname}
        description={member.firstname + ` ` + member.lastname + `, ` + member.lastname + `in Dryline Partners.`}/>
      <div className="container flex flex-col lg:flex-row items-start mx-auto pb-32">
        <div className="font-serif lg:w-1/4 p-10 lg:pr-10 lg:p-0 lg:py-28">
          <GatsbyImage
            data-sal="fade"
            data-sal-duration="1000"
            image={image}
            alt={member.firstname + ` ` + member.lastname}
          />

          <div className="w-full flex items-baseline justify-items-start py-5">
            <div className="flex items-baseline">
              <span className="text-purple font-sans">FOLLOW</span>
              <a className={isEmpty(member.email) ? 'hidden invisible' : 'visible px-2 flex items-center'} rel="noreferrer" target="_blank" href={"mailto:" + member.email}>
                <StaticImage
                  src={"../images/mail.png"}
                  width={15}
                  formats={["auto", "webp", "avif"]}
                  alt="Linkedin"
                />
              </a>
              <a className={isEmpty(member.linkedin) ? 'hidden invisible' : 'visible px-2 flex items-center'} rel="noreferrer" target="_blank" href={member.linkedin}>
                <StaticImage
                  src={"../images/linkedin.png"}
                  width={15}
                  formats={["auto", "webp", "avif"]}
                  alt="Linkedin"
                />
              </a>
              <a className={isEmpty(member.twitter) ? 'hidden invisible' : 'visible px-2 flex items-center'} rel="noreferrer" target="_blank" href={member.twitter}>
                <StaticImage
                  src={"../images/twitter.png"}
                  width={15}
                  formats={["auto", "webp", "avif"]}
                  alt="Twitter"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 flex flex-col p-10 lg:p-0 lg:py-28 border-r border-blue">
          <p data-sal="slide-left" data-sal-duration="1000" className="text-purple">
            <Link to="/team">TEAM</Link>
          </p>
          <h1 data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="text-black text-4xl pt-5 text-bold">
            {member.firstname + ` ` + member.lastname}
          </h1>
          <span data-sal="slide-left" data-sal-duration="1000" data-sal-delay="200" className="py-3 text-xl">
            {member.role}
          </span>
          <div data-sal="slide-left" data-sal-duration="1000" data-sal-delay="400" className="font-serif py-5 lg:pr-20 text-lg font-thin">
            {renderRichText(member.biography, options)}
          </div>
        </div>
        <div data-sal="slide-left" data-sal-duration="1000" data-sal-delay="800" className="lg:w-1/4 p-10 lg:p-0 lg:py-28 lg:px-10">
          <p className="text-purple">TRACK RECORD</p>
          <p className="font-serif py-10 text-lg lg:text-sm">
            {renderRichText(member.trackRecord, options)}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default MemberPageTemplate

export const pageQuery = graphql`
  query TeamMember($id: String!) {
    member: contentfulTeamMember(id: { eq: $id }) {
      id
      firstname
      email
      lastname
      linkedin
      twitter
      email
      role
      slug
      biography {
        raw
      }
      trackRecord {
        raw
      }
      avatar {
        gatsbyImageData(width: 400)
      }
    }
  }
`
